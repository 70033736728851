<template>
  <div class="onboarding-header__div">
    <div class="ob-header__div" v-if="responsiveView">
      <span class="ob-header__responsive">Getting to know you!</span>
    </div>
    <div class="ob-pro__desk_view">
      <LxpProgress
        :value="step"
        :max="totalSteps"
        :show-value="showValue"
      ></LxpProgress>
    </div>
    <div class="ob-pro__mob_view">
      <LxpProgress :value="step" :max="totalSteps + 1"></LxpProgress>
    </div>
    <div class="user-fluid-layout__header">
      <b-container fluid>
        <div class="ufl-header__div">
          <div class="ufl-header__left">
            <div class="ob-layout__logo">
              <img
                :src="
                  require(`@/assets/images/logo/whoa-logo-black-${logoLanguage}.svg`)
                "
                alt="WHO Academy"
                height="48"
                class="c-pointer ob-nav__mobileview"
                @click="$emit('logoClicked')"
              />
            </div>
          </div>
          <div class="ob-left__loader" v-if="step != 0">
            <ul>
              <li
                @click="navigateToStep(o.step)"
                v-for="(o, i) in loaderArr"
                :key="i"
                :class="{ active: o.active }"
              >
                <span class="ob-list__mobileview">{{ o.name }}</span>
                <span v-if="o.active">
                  <b-badge variant="primary" class="ob-left__badge">
                    {{ o.step }}
                  </b-badge>
                </span>
                <span
                  class="ob-header__uncheck"
                  v-else-if="o.isCompleted === false"
                >
                  <img
                    :class="{
                      'ob-step__uncheck_img': responsiveView === true
                    }"
                    src="@/assets/images/icons/unchecked-circle.svg"
                    alt="grey tick"
                    width="auto"
                    height="auto"
                  />
                </span>

                <span
                  class="ob-header_check"
                  v-if="o.isCompleted && o.step !== step"
                >
                  <img
                    :class="{
                      'ob-step__check_img': responsiveView === true
                    }"
                    src="@/assets/images/icons/checked-circle.svg"
                    alt="grey tick"
                    width="auto"
                    height="auto"
                  />
                </span>
              </li>
            </ul>
          </div>
          <div class="user-fluid-layout__settings">
            <div class="ob-lang__responsive">
              <LxpLanguageSelect
                :selected="selectedLanguage"
                :allLanguage="getAllLanguage()"
                :language="language"
                :parentClass="parentClass()"
                @set-language="setSelectedLanguage"
              />
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpProgress } from "didactica";
import getLogo from "@/core/mixins/getLogo";
import { LxpLanguageSelect } from "didactica";
import userLanguageMixin from "@/core/mixins/userLanguage";

export default {
  mixins: [getLogo, userLanguageMixin],
  props: {
    step: {
      type: Number,
      required: true
    },
    value: {
      type: Number
    },
    max: {
      type: Number
    },
    showValue: {
      type: Boolean,
      default: false
    },
    showProgressValue: {
      type: Boolean,
      default: false
    }
  },
  components: { LxpProgress, LxpLanguageSelect },
  data() {
    return {
      totalSteps: 6,
      responsiveView: false,
      selectedLanguage: {},
      onboardingSteps: [
        {
          name: "Location",
          active: true,
          isCompleted: false,
          step: 1
        },
        {
          name: "Date of Birth",
          active: true,
          isCompleted: false,
          step: 2
        },
        {
          name: "Gender",
          active: true,
          isCompleted: false,
          step: 3
        },
        {
          name: "Affiliation",
          active: true,
          isCompleted: false,
          step: 4
        },
        {
          name: "Experience",
          active: true,
          isCompleted: false,
          step: 5
        },
        {
          name: "Education",
          active: true,
          isCompleted: false,
          step: 6
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getAuthProfile"]),
    loaderArr() {
      this.onboardingSteps.forEach(o => {
        o.active = o.step === this.step;
        if (this.step >= 2) {
          for (let i = 0; i < this.step - 1; i++) {
            this.onboardingSteps[i].isCompleted = true;
          }
        }
      });
      return this.onboardingSteps;
    }
  },
  mounted() {
    if (window.innerWidth <= 990) {
      this.responsiveView = true;
    }
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.responsiveView = window.innerWidth <= 990;
    },
    navigateToStep(step) {
      this.$emit("navigateToStep", step);
    }
  }
};
</script>

<style lang="scss" scoped>
.onboarding-header__div {
  .ob-pro__mob_view {
    display: none;
  }
  .user-fluid-layout__header {
    background: transparent;
    height: $ufl-header-height;
    padding: 16px 20px;

    .container-fluid {
      padding: 0;
      .ufl-header__div {
        @include horizontal-space-between;
        .ufl-header__left {
          @include horizontal-space-between;
          .ob-layout__logo {
            padding-left: 24px;
          }
        }
        .user-fluid-layout__settings {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0;
        }
        .ob-left__loader {
          z-index: 2;
          ul {
            list-style: none;
            display: flex;
            flex-direction: row;
            padding: 0px 10px 0px 10px;
            justify-content: center;
            margin-bottom: 0px;
            li {
              @include label-medium($font-weight: 400);
              letter-spacing: 1px;
              color: $brand-inactive-text;
              padding: 6px 10px;
              display: flex;
              margin-right: 45px;
              font-style: normal;
              cursor: pointer;
              &.active {
                color: $brand-primary-400;
                font-weight: 400;
              }
              &.active:hover {
                background: $brand-primary-100;
                font-weight: 400;
              }
              &:hover {
                border-radius: 6px;
                background: $brand-neutral-50;
              }
              span {
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 990px) {
  .onboarding-header__div {
    background: $brand-primary-50;
    .ob-header__div {
      padding-top: 3%;
      padding-bottom: 1%;
      .ob-header__responsive {
        @include subtitle-regular;
      }
    }
    .ob-pro__desk_view {
      display: none;
    }
    .ob-pro__mob_view {
      display: block;
      position: relative;
      top: 17px;
      z-index: 1;
    }
    .user-fluid-layout__header {
      padding-top: 0px;
      height: 35px;
      .container-fluid {
        .ufl-header__div {
          .ob-layout__logo {
            display: none;
            padding-left: 0px;
            .ob-nav__mobileview {
              display: none;
            }
          }
          .ob-list__mobileview {
            display: none;
          }
          .ob-left__loader {
            ul {
              li {
                margin-right: 10px;
                padding-left: 25%;
                padding-right: 25%;
                .ob-left__badge {
                  z-index: 2;
                  font-size: 14px;
                  font-weight: 100;
                  padding: 3px 6px;
                }

                .ob-header__uncheck,
                .ob-header_check img {
                  z-index: 1;
                  position: relative;
                }
                .ob-step__uncheck_img,
                .ob-step__check_img {
                  background: $brand-primary-50;
                }
                &.active:hover {
                  background: transparent;
                }
                &:hover {
                  background: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 950px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 23%;
                padding-right: 23%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 890px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 21.5%;
                padding-right: 21.5%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 19%;
                padding-right: 19%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 17%;
                padding-right: 17%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 705px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 15%;
                padding-right: 15%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 660px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 13.5%;
                padding-right: 13.5%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 625px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 12%;
                padding-right: 12%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 555px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 10%;
                padding-right: 10%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 7%;
                padding-right: 7%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              justify-content: space-evenly;
              li {
                padding-left: 3%;
                padding-right: 3%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .onboarding-header__div {
    .ob-header__responsive {
      margin-right: 15px;
    }
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 4%;
                padding-right: 4%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 340px) {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .container-fluid {
        .ufl-header__div {
          .ob-left__loader {
            ul {
              li {
                padding-left: 3%;
                padding-right: 3%;
              }
            }
          }
        }
      }
    }
  }
}

[dir="rtl"] {
  .onboarding-header__div {
    .user-fluid-layout__header {
      .ufl-header__div {
        .user-fluid-layout__settings {
          justify-content: flex-end;
          left: auto;
        }
        .ufl-header__left {
          .ob-layout__logo {
            padding: 0 24px 0 0;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.onboarding-header__div {
  .lxp-progress__value {
    display: none !important;
  }
  .progress {
    height: 4px;
    background-color: $brand-primary-100 !important;

    .progress-bar {
      height: 4px;
      background-color: $brand-primary !important;
      border-radius: 6px;
    }
  }
  .ob-right__div {
    padding: 0% 10%;
    display: flex;
  }

  .ob-left__badge {
    border-radius: 20px;
    font-size: 12px;
    padding: 3px 5px;
    background-color: $brand-primary-400 !important;
    color: $brand-neutral-0 !important;
  }
  .ob-lang__responsive {
    .ufl-language__div {
      position: absolute;
      top: 4rem;
      left: auto;
    }
  }
}

@media screen and (max-width: 990px) {
  .onboarding-header__div {
    .progress {
      height: 1px !important;
      background-color: $brand-primary-100 !important;

      .progress-bar {
        height: 1px !important;
        background-color: $brand-primary !important;
        border-radius: 6px;
      }
    }
    .ob-lang__responsive {
      .user-fluid-layout__language {
        margin-top: -55px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ob-lang__responsive {
    .user-fluid-layout__language {
      .ufl-language__div {
        right: -20px;
        top: 2rem;
        margin-top: 15px;
        .ufl-language__list {
          width: calc(100vw);
          max-height: calc(100vh - 60px);
          border-radius: 16px 16px 0px 0px;
          .ufl-language__item {
            padding: 8px 16px;
          }
        }
      }
    }
  }
  .onboarding_bg {
    overflow: hidden;
  }
}
@media screen and (max-width: 360px) {
  .ob-lang__responsive {
    .user-fluid-layout__language {
      margin-left: -10px;
      .lxp-dropdown__menu-text {
        padding: 8px 2px;
      }
    }
  }
}
[dir="rtl"] {
  .ob-lang__responsive {
    .ufl-language__div {
      left: 5px !important;
      .ufl-language__item.ufl-language__selected-item:after {
        right: auto !important;
      }
    }
  }
}
</style>
